import React, { useState, useEffect } from 'react';
import * as s from "./style.module.css"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "reactstrap"

const Popup = () => {
  const [show, setShow] = useState(false);

  //useEffect(() => {
    // Check if popup has been shown before
  //  const hasSeenPopup = localStorage.getItem('hasSeenPopup');

  //  if (!hasSeenPopup) {
 //     const timer = setTimeout(() => {
  //      setShow(true);
  //      localStorage.setItem('hasSeenPopup', 'true'); // Mark popup as seen
 //     }, 3000);

 //     return () => clearTimeout(timer);
 //   }
 // }, []);

  useEffect(() => {
      const timer = setTimeout(() => {
       setShow(true);
      }, 3000);

      return () => clearTimeout(timer);
  }, []);

  const handleButtonClick = () => {
    window.open("https://app.evenea.pl/event/hack4change2025/", "_blank");
    setShow(false);
  };

  return (
    <div className={`${s.popupOverlay} ${show ? s.show : s.hide}`}>
      <div className={s.popupContainer}>
        <button className={s.popupClose} onClick={() => setShow(false)}>✕</button>
        <StaticImage placeholder="tracedSVG" src="./images/Sprzątanie-rzeki-Redy-hack4change-2025.jpg" alt="Sprzatanie rzeki Redy" className={s.popupImage} />
        <Button onClick={handleButtonClick} className={s.button}>Weź udział!  &rarr;</Button>
      </div>
    </div>
  );
};

export default Popup;